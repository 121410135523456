<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{navigation, color: 'rgba(0, 0, 0, .5)'}"/>
      </Section>
      <Section limiter>
        <Row justify="between">
          <AccountMenu v-if="$laptop"
                       class="grid-md-4 grid-lg-4"/>
          <AccountContent class="grid-sm-full grid-md-8 grid-lg-8"
                          :params="{transparent: true}">
            <Row class="account-content__facts-header m-b-40"
                 justify="between">
              <h5 class="account-content__facts-title">Мои факты</h5>
              <Button v-if="$laptop"
                      color="main"
                      v-on:click.native="$openPopupAddFact">Добавить факт
              </Button>
            </Row>
            <Row justify="between"
                 wrap>
              <InterestingFact :key="fact.id"
                               :params="{fact}"
                               v-for="fact in $my.facts"/>
            </Row>
          </AccountContent>
        </Row>
      </Section>
<ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountFactsView',
  data() {
    return {
      navigation: [{
        href: '/',
        title: 'Главная',
      }, {
        href: '/account',
        title: 'Личный кабинет',
      }],
    };
  },
  created() {
    this.$store.dispatch('GET_MY_FACTS_FROM_SERVER');
  },
};
</script>
